import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter, Route} from 'react-router-dom'
import './scss/main.scss'

import store from './redux/store'
import { Provider } from 'react-redux'

ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <div>
          <Route path="/conciliation/:idMerchant" component={App}/>
        </div>
      </BrowserRouter>
    </Provider>
    , document.getElementById('wepay4u')
)
serviceWorker.unregister()
