import React from 'react'
import Header from '../organisms/Header'
import Footer from '../organisms/Footer'
import ListBanksOnline from '../organisms/ListBanksOnline'

const Home = ({ db , idMerchant}) => {
	return (
        <>
			<Header db={db} />
			<main className="wrap">
				<div className="tabs-container">
						<h3>Selecciona tu banco para ver las instrucciones de pago</h3>
						<ListBanksOnline db={db} idMerchant={idMerchant} />
				</div>
			</main>
			<Footer />
        </>
	)
}

export default Home
