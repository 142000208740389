import React from 'react'
import logo from '../../images/logodark.png'
import logoMerchant from '../../images/Logodoradobet.png'
import '../../scss/main.scss'

const Header = ({ db }) => {
    return (
		    <header>
            <div className="w">
              <div className="Lmcnt">
                <img src={db.Data.Agent.Url_logo} className="logoMerchant" alt="Banner" />
              </div>
              <div className="Lwp4u">
                <img src={logo} className="logoWepay" alt="Banner" />
              </div>
            </div>

        </header>
    )
}

export default Header
