import React, {useState} from 'react'
import { connect } from 'react-redux'
import { updateStatus } from '../../redux/actionsCreators'
import axios from 'axios'


    const ListBanksOnline = ({ db, idMerchant }) => {
    const listBanksOnline = db.Data.ListBanks

    const fecha = new Date();

    const urlSearchOperacion = 'https://dev-payment.wepay4u.com/api/v1/conciliation/search_operation'
    const urlCreateTransaction = 'https://dev-payment.wepay4u.com/api/v1/conciliation/create_transaction'

    //const urlSearchOperacion = 'https://stg-payment.wepay4u.com/api/v1/conciliation/search_operation'
    //const urlCreateTransaction = 'https://stg-payment.wepay4u.com/api/v1/conciliation/create_transaction'

    //const urlSearchOperacion = 'https://payment.wepay4u.com/api/v1/conciliation/search_operation'
    //const urlCreateTransaction = 'https://payment.wepay4u.com/api/v1/conciliation/create_transaction'

    const tokenMerchant = idMerchant

    const [disInput, setdisInput] = useState(0)

    const [slide, setSlide] = useState( listBanksOnline && listBanksOnline.length > 0 ? 0 : undefined )
    const [color, setColor] = useState (listBanksOnline[0].Color)
    const [shortName, setShortName] = useState(listBanksOnline[0].ShortName)
    const [urlBank, setUrlBank] = useState(listBanksOnline[0].UrlBank)
    const [version, setVersion] = useState('1.0')
    const [instructions, setInstructions] = useState(listBanksOnline[0].Instructions)
    const [loader, setLoader] = useState('desactive')
    const [bankCode, setBankCode] = useState(listBanksOnline[0].BankCode)

    const [serchOperation, setSerchOperation] = useState({token:tokenMerchant, number_operation:'', date:'', bank_code:''})
    const [responseSerchOperation, setResponseSerchOperation] = useState(null)

    const [createOperation, setCreateOperation] = useState({token:tokenMerchant,  cupo_juego:0, cupo_recarga:0, bank_code:'', number_operation:'', date_process:''})
    const [responseCreateOperation, setResponseCreateOperation] = useState(null)

    const [value,setValue] = useState(0);
    const [juego, setJuego] = useState(0);
    const [rec, setRec] = useState(0);
    const [mensajeErrorSuma, setMensajeErrorSuma] = useState('');





    function sendSerchOperation(event){

      serchOperation.token = tokenMerchant
      serchOperation.number_operation = document.getElementById('inputCodigo').value
      serchOperation.date = fecha
      serchOperation.bank_code = bankCode

      setLoader('active')
      axios.post(urlSearchOperacion, serchOperation)
            .then(response => {
              setResponseSerchOperation(response.data);
              console.log("res-> "+response.data.data.Amount);
              setValue(parseFloat(response.data.data.Amount));
              setdisInput(1)
            }).catch(error => {
                console.log(error)
        });

      setLoader('desactive')
      }

      function sendCreateOperation(event){

        let precision = 0.001;

        console.log(Math.abs(value - (rec+juego)));
        console.log("value: "+value);
        console.log("juego: "+juego);
        console.log("rec: "+rec);

        if(Math.abs(value - (rec+juego)) <= precision){

          createOperation.token = tokenMerchant
          createOperation.cupo_juego = juego;
          createOperation.cupo_recarga = rec;
          createOperation.date_process = fecha
          createOperation.bank_code = bankCode;
          createOperation.number_operation = document.getElementById('inputCodigo').value


          setLoader('active')
          axios.post(urlCreateTransaction, createOperation)
          .then(response => {
            if (response.data.data){
              setResponseSerchOperation(null)
            }
            setResponseCreateOperation(response.data);
            setdisInput(1)
          })
          setLoader('desactive')

        }else{
          setMensajeErrorSuma('La suma de Cupo Juego y Cupo Recarga debe ser igual al Monto de la operación');
          console.log("No pasa");
        }
      }


    function goLeft(params){

        setSlide( params ? params - 1 : listBanksOnline.length - 1)
        for( let i = 0; i < listBanksOnline.length; i++ ){
            if( params === i && i !== -1){
                const newIndex = i - 1
                if(newIndex !== -1){
                    setColor(listBanksOnline[newIndex].Color)
                    setShortName(listBanksOnline[newIndex].ShortName)
                    setUrlBank(listBanksOnline[newIndex].UrlBank)
                    setVersion(listBanksOnline[newIndex].Version)
                    setInstructions(listBanksOnline[newIndex].Instructions)
                    setBankCode(listBanksOnline[newIndex].BankCode)

                }else{
                    setColor(listBanksOnline[listBanksOnline.length - 1].Color)
                    setShortName(listBanksOnline[listBanksOnline.length - 1].ShortName)
                    setUrlBank(listBanksOnline[listBanksOnline.length - 1].UrlBank)
                    setVersion(listBanksOnline[listBanksOnline.length - 1].Version)
                    setInstructions(listBanksOnline[listBanksOnline.length - 1].Instructions)
                    setBankCode(listBanksOnline[listBanksOnline.length - 1].BankCode)
                }
            }
        }
    }

    function goRight(params){
        setSlide((slide + 1) % listBanksOnline.length)
        for( let i = 0; i < listBanksOnline.length; i++ ){
            if( params === i && i !== listBanksOnline.length ){
                const newIndex = i + 1 % listBanksOnline.length
                if(newIndex !== listBanksOnline.length){
                    setColor(listBanksOnline[newIndex].Color)
                    setShortName(listBanksOnline[newIndex].ShortName)
                    setUrlBank(listBanksOnline[newIndex].UrlBank)
                    setVersion(listBanksOnline[newIndex].Version)
                    setInstructions(listBanksOnline[newIndex].Instructions)
                    setBankCode(listBanksOnline[newIndex].BankCode)

                }else{
                    setColor(listBanksOnline[0].Color)
                    setShortName(listBanksOnline[0].ShortName)
                    setUrlBank(listBanksOnline[0].UrlBank)
                    setVersion(listBanksOnline[0].Version)
                    setInstructions(listBanksOnline[0].Instructions)
                    setBankCode(listBanksOnline[0].BankCode)
                }
            }
        }
    }

    const getBank = params => () =>  {
        setSlide(params)
        for( let i = 0; i < listBanksOnline.length; i++ ){
            if( params === i){
                setColor(listBanksOnline[i].Color)
                setShortName(listBanksOnline[i].ShortName)
                setUrlBank(listBanksOnline[i].UrlBank)
                setInstructions(listBanksOnline[i].Instructions)
                setVersion(listBanksOnline[i].Version)
                setBankCode(listBanksOnline[i].BankCode)

            }
        }
    }



    const handleChangeCupos = (e) => {

      setMensajeErrorSuma('');

      if(e.target.name == "juego"){
        setJuego(parseFloat(e.target.value));

      }
      if(e.target.name == "recarga"){
        setRec(parseFloat(e.target.value));

      }

    }

    return(
        <>
            <div className="wrap-slider">
                <ul className={`slider s-`+slide} >
                    { listBanksOnline.map( (item, index) => (
                        <li key={item.BankID}
                            className={index === slide ?  `slide-item ${slide} active` : `slide-item ${index}`}>
                            <button onClick={ getBank(index) }  >
                                <img className="img-bank" src={item.UrlLogo} alt={item.ShortName} />
                            </button>
                        </li>
                    ))}
                </ul>
                <button className="btn-arrow-left" data={slide} onClick={() => goLeft(slide)}>atrás</button>
                <button className="btn-arrow-right" data={slide} onClick={() => goRight(slide)}>adelante</button>
            </div>

            <div  className={loader === 'active' ?  `c-loading active` : `c-loading`} >
              <div className="progress-content__box">
                <div data-status="loading" className="spinner-loader">
                  <ul role="progressbar" aria-busy="true" aria-label="Loading domino shop">
                    <li role="presentation"></li>
                    <li role="presentation"></li>
                    <li role="presentation"></li>
                    <li role="presentation"></li>
                    <li role="presentation"></li>
                    <li role="presentation"></li>
                    <li role="presentation"></li>
                  </ul>
                </div>
                <p>Esperando confirmación de pago...</p>
              </div>
            </div>

              <div>
                  <div>
                    <ul className="list-steps-0">
                        <li key={instructions[0].step}>
                            <p><strong style={{ color: color}}>Paso {instructions[0].step}: </strong></p>
                            {instructions[0].icon ?  <img style={{marginBottom: '20px'}} src={instructions[0].icon} alt={instructions[0].step} />  : '' }
                            <div className="separador"></div>
                            <p><span dangerouslySetInnerHTML={ {__html: instructions[0].text} }></span></p>
                            <div className="BankAccounts">
                              <p> Cuenta en Soles:   <strong>{instructions[0].BankAccounts[0].CurrencyCode}</strong></p>
                              <p> Numero de cuenta:   <strong>{instructions[0].BankAccounts[0].NumberAccount}</strong></p>
                              <p> Cuenta Interbancaria:   <strong>{instructions[0].BankAccounts[0].CCI}</strong></p>
                            </div>

                            {(instructions[0].BankAccounts[1])?(
                              <div className="BankAccounts">
                                <p> Cuenta en Dorales:   <strong>{instructions[0].BankAccounts[1].CurrencyCode}</strong></p>
                                <p> Numero de cuenta:   <strong>{instructions[0].BankAccounts[1].NumberAccount}</strong></p>
                                <p> Cuenta interbancaria:   <strong>{instructions[0].BankAccounts[1].CCI}</strong></p>
                              </div>
                            ):''}

                        </li>
                        <li key={instructions[1].step}>
                            <p><strong style={{ color: color}}>Paso {instructions[1].step}: </strong></p>
                            {instructions[1].icon ?  <img style={{marginBottom: '20px'}} src={instructions[1].icon} alt={instructions[1].step} />  : '' }
                            <div className="separador"></div>
                            <p><span dangerouslySetInnerHTML={ {__html: instructions[1].text} }></span></p>
                            <form>
                              {(!disInput) ? (
                                <div>
                                <input id="inputCodigo" name="number" type="text" placeholder="Numero de operación"/>
                                <button type="button" onClick={sendSerchOperation}>Enviar</button>
                                </div>
                              ) :
                              <div>
                                <input disabled id="inputCodigo" name="number" type="text" placeholder="Numero de operación"/>
                                <button disabled type="button" onClick={sendSerchOperation}>Enviar</button>
                              </div>
                            }
                            </form>


                            {(responseSerchOperation !=null )? (
                              <div>
                                {(responseSerchOperation.status) ? (
                                <div className="responseProcess">
                                  <div className="infoPocess">
                                    <div>
                                      <h4>Agente</h4>
                                      <p>{responseSerchOperation.data.AgentName}</p>
                                    </div>
                                    <div>
                                      <h4>Monto</h4>
                                      <p>{responseSerchOperation.data.Amount}</p>
                                    </div>
                                    <div>
                                      <h4>Moneda</h4>
                                      <p>{responseSerchOperation.data.CurrencyCode}</p>
                                    </div>
                                  </div>
                                  <div className="formInfoProcess">
                                    <form>
                                      <div>
                                        <label>Cupo Juego: </label>
                                        <input type="number" step="0.01" value={juego|| ''} name="juego" onChange={handleChangeCupos}></input>
                                      </div>
                                      <div>
                                        <label>Cupo Recarga: </label>
                                        <input type="number" step="any" value={rec|| ''} name="recarga" onChange={handleChangeCupos}></input>
                                      </div>
                                      {(mensajeErrorSuma)?(
                                        <div>
                                          <p className="errorSum">{mensajeErrorSuma}</p>
                                        </div>
                                      ):''}
                                      <div>
                                        <p>Total: {(juego+rec)}</p>
                                      </div>
                                      <button type="button" onClick={sendCreateOperation}><span>CONFIRMAR</span></button>
                                    </form>
                                  </div>
                                </div>
                                ):(
                                <div>
                                  {(responseSerchOperation.message)?(
                                    <div className="responseProcess">
                                      <div dangerouslySetInnerHTML={{__html: responseSerchOperation.message}}/>
                                    </div>
                                  ):
                                  <p>E</p>
                                }

                                </div>
                                )}
                            </div>
                            ):''}

                            {(responseCreateOperation)?(
                              <div className="responseProcess">
                                {(responseCreateOperation.data)?(
                                  <div>
                                    <div dangerouslySetInnerHTML={{__html: responseCreateOperation.message}}/>
                                  </div>
                                ):(
                                  <div className="BankAccounts">
                                    {Object.keys(responseCreateOperation.message).map((index)=>(
                                      <p key={index}>{responseCreateOperation.message[index]}</p>
                                    ))}
                                  </div>
                                )}
                              </div>
                            ):''}

                        </li>
                    </ul>
                  </div>


            </div>

            <div className="info">
              <p><b>Horario de atención de {shortName}</b> - de Lunes a viernes de 9 am. a 6 pm. - Sábado de 9 am. a 1 pm.</p>
            </div>
        </>
    )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
    getStatus(newStatus){
        dispatch(updateStatus(newStatus))
        console.log(newStatus)
    }
})




export default connect(mapStateToProps, mapDispatchToProps)(ListBanksOnline)
