import React, {  useEffect } from 'react'
import store from '../src/redux/store'
import { getPayment } from '../src/redux/actionsCreators'
import { useParams} from 'react-router-dom'
import { connect } from 'react-redux'
import Expired from './components/pages/Expired'
import Home from './components/pages/Home'
import Success from './components/pages/Success'

const App = ({ payment }) => {
	//console.log(status)
	//const [valid , setValid] = useState(status = 0)
	//console.log(status)

	let { idMerchant } = useParams();



	useEffect( () => {


		store.dispatch(getPayment(idMerchant))

		const interval = setInterval(() => {
			store.dispatch(getPayment(idMerchant))
		}, 6000);
		return () => clearInterval(interval);
	}, [])


	// console.log(this.props.match.params.idMerchant);
	if ( payment) {
		const isStatusActive = payment.status
		if( isStatusActive === true)
					return <Home db={payment} idMerchant={idMerchant}/>
	}else{
		return null
	}
}

const mapStateToProps = state => ({
	payment: state.paymentReducer.payment,
	status: state.statusReducer.data
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(App)
